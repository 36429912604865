import React from 'react';
import 'bootstrap';
import Layout from '../components/layout'
import { graphql } from 'gatsby';

export default (props) => {
  const {data, location} = props;
  return (
    <Layout data={data} location={location}>
      <h1 className='text-center'>Contact Us</h1>
      <p className='text-center'>Have a question or comment? We would love to hear from you.</p>
      <form name='contact' method='POST' data-netlify='true'>
        <div className="form-group">
          <label for="name">Name</label>
          <input type="name" className="form-control" id="name" name='name' placeholder="John Wayne"/>
        </div>
        <div className="form-group">
          <label for="email">Email</label>
          <input type="email" className="form-control" id="email" name='email' placeholder="name@example.com"/>
        </div>
        <div className="form-group">
          <label for="message">Message</label>
          <textarea className="form-control" id="message" name='message' rows="3"></textarea>
        </div>
        <button type="submit" className="btn btn-primary">Submit</button>
      </form>
    </Layout>
  );
}

export const contactQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        type
        template
        red_track
        description
      }
    }
  }
`;